import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import ProjectForm from 'erpcore/screens/Projects/components/ProjectForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { dtoForm } from 'erpcore/utils/dto';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const ProjectEdit = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const projectDataForm = dtoForm(projectData) || {};
    const fetching = useSelector(getProjectFetching);

    const initialValues = {
        name: projectDataForm.name,
        is_active: projectDataForm.is_active,
        client: projectDataForm.client,
        headline: projectDataForm.headline,
        description: projectDataForm.description,
        homes: projectDataForm.homes,
        bedrooms: projectDataForm.bedrooms,
        number_of_homes: projectDataForm.number_of_homes,
        status: projectDataForm.status,
        website: projectDataForm.website,
        matterport_url: projectDataForm.matterport_url,
        intro_video_url: projectDataForm.intro_video_url,
        development_project_logo: projectDataForm.development_project_logo,
        featured_image: projectDataForm.featured_image,
        main_gallery: projectData.main_gallery,
        purchase_calculators: projectData.purchase_calculators?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        location: {
            full_address: projectDataForm.full_address,
            street: projectDataForm.street,
            country: projectDataForm.country,
            state: projectDataForm.state,
            city: projectDataForm.city,
            zip: projectDataForm.zip,
            latitude: projectDataForm.latitude,
            longitude: projectDataForm.longitude
        }
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        let formDataDiff = diff(initialValues, formData);
        // Handle data
        const { location, main_gallery: mainGallery, purchase_calculators: purchaseCalculators } = {
            ...formDataDiff
        };
        if (location) {
            formDataDiff = setLocationData('location', formDataDiff);
        }
        if (mainGallery) {
            formDataDiff.main_gallery = formData.main_gallery;
        }
        if (purchaseCalculators) {
            formDataDiff.purchase_calculators = cleanRepeaterOutput(formData.purchase_calculators, [
                'customIdentifier'
            ]);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <ProjectForm
                    onSubmit={onSubmit}
                    form={`ProjectEditForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEdit.defaultProps = {
    match: {}
};

ProjectEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEdit);
