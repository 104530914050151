import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import ElementLoader from 'erpcore/components/ElementLoader';
import LayoutManager from 'erpcore/utils/LayoutManager';
import OrganizationBrandingForm from 'erpcore/screens/Settings/Organization/components/OrganizationBrandingForm';
import OrganizationTabs from 'erpcore/screens/Settings/Organization/components/OrganizationTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import {
    getOrganizationSettings,
    getFetchingOrganizationSettings
} from 'erpcore/screens/Settings/Organization/Organization.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';

const OrganizationBranding = () => {
    const title = 'Organization';
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const organizationSettings = useSelector(state => getOrganizationSettings(state));
    const fetchingOrganization = useSelector(state => getFetchingOrganizationSettings(state));

    const initialValues = {
        name: organizationSettings?.settings?.name,
        primaryColor: organizationSettings?.settings?.primaryColor,
        buttonColor: organizationSettings?.settings?.buttonColor,
        sidebarColor: organizationSettings?.settings?.sidebarColor,
        textColor: organizationSettings?.settings?.textColor,
        logo: organizationSettings?.settings?.logo,
        logoInverted: organizationSettings?.settings?.logoInverted,
        logoIcon: organizationSettings?.settings?.logoIcon,
        logoIconInverted: organizationSettings?.settings?.logoIconInverted,
        loginBackground: organizationSettings?.settings?.loginBackground,
        bodyFont: organizationSettings?.settings?.bodyFont,
        titleFont: organizationSettings?.settings?.titleFont,
        favicon: organizationSettings?.settings?.favicon,
        facebook: organizationSettings?.settings?.facebook,
        linkedIn: organizationSettings?.settings?.linkedIn,
        twitter: organizationSettings?.settings?.twitter,
        footerDisclaimer: organizationSettings?.settings?.footerDisclaimer,
        introText: organizationSettings?.settings?.introText,
        disclosureForm: organizationSettings?.settings?.disclosureForm,
        emailLogo: organizationSettings?.settings?.emailLogo,
        emailLogoInverted: organizationSettings?.settings?.emailLogoInverted
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                iri: organizationSettings.iri,
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTINGS,
                formData
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const fetchMeData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_FETCHING_ME
            });
        }).catch(error => ({ error }));
    };

    const fetchOrganization = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_ORGANIZATION_SETTINGS
            });
        }).catch(error => ({ error }));
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchMeData().finally(() => {
            fetchOrganization().finally(() => {
                setInitialized(true);
            });
        });
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <OrganizationTabs />
                {!!fetchingOrganization && <ElementLoader overlay />}
                {!!initialized && (
                    <OrganizationBrandingForm
                        onSubmit={onSubmit}
                        form="OrganizationBrandingForm"
                        submitLabel="Save"
                        initialValues={initialValues}
                        isCurrencyDisabled={initialValues?.currency}
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default OrganizationBranding;
