import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { SubmissionError } from 'redux-form';
import { getIdFromIri } from 'erpcore/utils/dto';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import UserCreateForm from 'erpcore/screens/Users/components/UserCreateForm';

import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getListingResponse } from 'erpcore/components/Listing/Listing.selectors';
import './UserCreate.scss';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const UserCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [roles, setRoles] = useState([]);
    const rolesData = useSelector(state => getListingResponse(state, 'roles'));
    const title = 'Add new user';

    const onSubmit = formData => {
        if (formData.roles === null) {
            formData.roles = [];
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CREATE_USER,
                formData
            });
        })
            .then(response => {
                history.push(`/users/${getIdFromIri(response?.data?.id)}/edit/details`);
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    // Fetch Roles
    const fetchRoles = () => {
        const params = { pagination: false };

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ROLES',
            name: 'roles',
            endpoint: 'api/roles'
        });
    };

    // Roles for the checkbox group
    const createRolesData = () => {
        const rolesArray = [];

        if (rolesData?.data) {
            rolesData.data.map(role => {
                rolesArray.push({ value: role.iri, id: role.iri, label: role.name });

                return rolesArray;
            });
        }

        return setRoles(rolesArray);
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        createRolesData();
    }, [rolesData]);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <UserCreateForm
                    onSubmit={onSubmit}
                    form="UserCreateForm"
                    submitLabel="Create"
                    roles={roles}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default UserCreate;
