import React from 'react';
import { Field, reduxForm } from 'redux-form';

import Form, { Text } from 'erpcore/components/Form';

const ImageEditableMeta = () => {
    return (
        <Form className="image-editor__editable-meta" onSubmit={() => {}}>
            <Form.Row>
                <Field
                    name="alt"
                    fieldProps={{
                        label: 'Alt',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-alt' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="title"
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-title' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="caption"
                    fieldProps={{
                        label: 'Caption',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'image-meta-caption' }}
                    component={Text}
                />
            </Form.Row>
        </Form>
    );
};

ImageEditableMeta.defaultProps = {};

ImageEditableMeta.propTypes = {};

export default reduxForm({
    form: 'ImageMeta',
    enableReinitialize: true
})(ImageEditableMeta);
