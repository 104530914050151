import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageLoader from 'erpcore/components/PageLoader';
import Favicon from 'erpcore/components/Favicon';
import { hexToRGB } from 'erpcore/utils/utils';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import {
    getOrganizationSettings,
    getFetchingOrganizationSettingsPublic
} from 'erpcore/screens/Settings/Organization/Organization.selectors';

const OrganizationSettings = ({ children }) => {
    const dispatch = useDispatch();
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const fetchingOrganizationSettings =
        useSelector(state => getFetchingOrganizationSettingsPublic(state)) || false;

    const fetchOrganizationSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_ORGANIZATION_SETTINGS_PUBLIC
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchOrganizationSettings();
    }, []);

    const { primaryColor, sidebarColor, buttonColor, textColor, favicon, titleFont, bodyFont } = {
        ...organizationSettings.settings
    };

    return (
        <>
            {!!favicon && <Favicon iri={favicon} />}
            <Helmet>
                <style type="text/css">
                    {`
                        :root {
                            ${titleFont ? `--titleFont: '${titleFont}';` : ''}
                            ${bodyFont ? `--bodyFont: '${bodyFont}';` : ''}
                            ${
                                primaryColor
                                    ? `--primaryColor: ${hexToRGB(primaryColor, false, true)};`
                                    : ''
                            }
                            ${
                                sidebarColor
                                    ? `--sidebarColor: ${hexToRGB(sidebarColor, false, true)};`
                                    : ''
                            }
                            ${
                                buttonColor
                                    ? `--buttonColor: ${hexToRGB(buttonColor, false, true)};`
                                    : ''
                            }
                            ${textColor ? `--textColor: ${hexToRGB(textColor, false, true)};` : ''}
                        }
                    `}
                </style>
            </Helmet>
            {fetchingOrganizationSettings ? (
                <PageLoader className="page-loader--opaque" />
            ) : (
                children
            )}
        </>
    );
};

OrganizationSettings.defaultProps = {
    children: null
};

OrganizationSettings.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node])
};

export default OrganizationSettings;
