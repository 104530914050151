import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import restClient from 'erpcore/api/restClient';

import Button from 'erpcore/components/Button';
import CurrencyFormatter from 'erpcore/components/CurrencyFormatter';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import ViewEmployeeHistory from 'erpcore/screens/Reports/EmployeeRecords/components/ViewEmployeeHistory';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

const EmployeeRecordsListing = ({
    currency,
    fetch,
    reducerName,
    listingData,
    listingFetching,
    apiParams
}) => {
    const dispatch = useDispatch();
    const [exportingCSV, setExportingCSV] = useState(false);

    const exportCSV = () => {
        setExportingCSV(true);

        restClient
            .get(`api/reports/employee-records-export`, {
                params: parseParamsForApi(apiParams),
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                setExportingCSV(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report-employee-records-export.csv`);
                link.click();
            })
            .catch(() => {
                setExportingCSV(false);
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'Employee',
                field: 'employee',
                mobile: 'title'
            },
            {
                title: 'Annual salary',
                field: 'annualSalary'
            },
            {
                title: 'Billing cycle',
                field: 'billingCycle'
            },
            {
                title: 'Monthly salary',
                field: 'monthlySalary'
            },
            {
                title: 'Job title',
                field: 'jobTitle'
            },
            {
                title: 'Employment type',
                field: 'employmentType'
            },
            {
                title: 'Department',
                field: 'department'
            },
            {
                title: 'Reports to',
                field: 'reportsTo'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listingData.data) {
            listingData.data.map(row => {
                const {
                    id,
                    iri,
                    first_name: firstName,
                    last_name: lastName,
                    normalized_annual_salary: annualSalary,
                    salary_payout_cycle: salaryPayoutCycle,
                    normalized_monthly_salary: monthlySalary,
                    job_title: jobTitle,
                    employment_type: employmentType,
                    department,
                    reports_to: reportsTo
                } = {
                    ...row
                };
                const { first_name: reportsToFirstName, last_name: reportsToLastName } = {
                    ...reportsTo
                };
                return table.data.push({
                    id,
                    iri,
                    employee: `${firstName || ''} ${lastName || ''}`,
                    annualSalary: (
                        <CurrencyFormatter
                            amount={
                                annualSalary?.amount !== undefined
                                    ? annualSalary?.amount / 100
                                    : null
                            }
                            currency={annualSalary?.currency || null}
                        />
                    ),
                    billingCycle: salaryPayoutCycle,
                    monthlySalary: (
                        <CurrencyFormatter
                            amount={
                                monthlySalary?.amount !== undefined
                                    ? monthlySalary?.amount / 100
                                    : null
                            }
                            currency={monthlySalary?.currency || null}
                        />
                    ),
                    jobTitle: jobTitle?.name,
                    employmentType,
                    department: department?.name,
                    reportsTo: `${reportsToFirstName || ''} ${reportsToLastName || ''}`,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <ViewEmployeeHistory data={row} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <Listing
            name={reducerName}
            reducerName={reducerName}
            loading={listingFetching}
            meta={listingData?.meta}
            table={tableData()}
            onListingConfigUpdate={params => fetch(params)}
            initialFetch={false}
            hideFilters
            hideSearch
            asideSpaceBetween
        >
            <span>
                All values are displayed in organization currency: <strong>{currency}</strong>
            </span>
            <Button
                variation="secondary"
                size="small"
                label="Export CSV"
                loading={exportingCSV}
                onClick={() => exportCSV()}
            />
        </Listing>
    );
};

EmployeeRecordsListing.defaultProps = {
    currency: null,
    fetch: () => {},
    reducerName: 'employeeRecordsReport',
    apiParams: null,
    listingData: {},
    listingFetching: false
};

EmployeeRecordsListing.propTypes = {
    currency: PropTypes.string,
    fetch: PropTypes.func,
    reducerName: PropTypes.string,
    apiParams: PropTypes.oneOfType([PropTypes.object]),
    listingData: PropTypes.oneOfType([PropTypes.object]),
    listingFetching: PropTypes.bool
};

export default EmployeeRecordsListing;
