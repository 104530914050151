import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import { getOrganizationSettings } from 'erpcore/screens/Settings/Organization/Organization.selectors';

const HeadMeta = ({ title, description }) => {
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};

    const getTitle = () => {
        const reactAppName =
            organizationSettings?.settings?.name || enviromentVariables?.REACT_APP_NAME || 'EAS';

        if (title) {
            return `${title} - ${reactAppName}`;
        }
        return reactAppName;
    };

    return (
        <Helmet>
            <title>{getTitle()}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
};

HeadMeta.defaultProps = {
    title: null,
    description: 'Description'
};

HeadMeta.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default HeadMeta;
