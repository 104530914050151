import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
// import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { Repeater, Media } from 'erpcore/components/Form';
import TitleDescriptionRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleDescriptionRepeaterItem';

const FeatureAndOptionsForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleSmall>Features Gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="features_gallery"
                    id="features_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Color board</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="home_tour_color_board"
                    id="home_tour_color_board"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Features list</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="features_list"
                    component={Repeater}
                    RepeatableItem={TitleDescriptionRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.SectionTitleSmall>Options gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="options_gallery"
                    id="options_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

FeatureAndOptionsForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

FeatureAndOptionsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'FeatureAndOptionsForm',
    enableReinitialize: true
})(FeatureAndOptionsForm);
