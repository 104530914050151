import breakpoints from 'erpcore/assets/scss/exports/breakpoints.module.scss';
import grid from 'erpcore/assets/scss/exports/grid.module.scss';
import moment from 'moment-timezone';

/**
 * Convert string property.id to int.
 * Sort property items by defined positions first, undefined/null positions last.
 * Then sort items with defined positions by property.position ascending.
 * If positions are equal then sort by property.id (ascending).
 * Sort group of property items with undefined/null positions by id (ascending).
 *
 * @param list {array}
 * @param includeDeleted {boolean}
 * @return {Object}
 */
const dtoSortByPositionAndId = ({ list = [], includeDeleted = false }) => {
    if (list?.length) {
        const listCopy = [
            ...list.reduce((accumulator, current) => {
                if (includeDeleted || (!includeDeleted && current?.deleted !== true)) {
                    accumulator.push({ ...{}, ...current });
                }
                return accumulator;
            }, [])
        ];
        if (listCopy?.length) {
            listCopy.forEach((item, index) => {
                if (!Number.isNaN(parseInt(item?.id, 10))) {
                    listCopy[index].id = parseInt(item?.id, 10);
                }
            });

            // sort ticket types
            listCopy.sort((firstItem, secondItem) => {
                // if position is not set, treat position as a 999999 (otherwise null position would evaluate as less than any other defined position)
                const firstItemPosition =
                    firstItem.position || firstItem.position === 0 ? firstItem.position : 999999;
                const secondItemPosition =
                    secondItem.position || secondItem.position === 0 ? secondItem.position : 999999;

                let compare = 0;
                //  0 = order is unchanged
                //  1 = secondItem takes precedence over firstItem
                // -1 = firstItem takes precedence over secondItem
                if (firstItemPosition === secondItemPosition) {
                    if (firstItem.id > secondItem.id) {
                        compare = 1;
                    } else if (firstItem.id < secondItem.id) {
                        compare = -1;
                    }
                } else if (firstItemPosition > secondItemPosition) {
                    compare = 1;
                } else if (firstItemPosition < secondItemPosition) {
                    compare = -1;
                }
                return compare;
            });
        }
        return listCopy;
    }
    return list;
};

/**
 *
 * @param datetime
 * @return {string}
 */
const dtoIgnoreTimezone = (datetime = null) => {
    if (!datetime) {
        return null;
    }
    const m = moment.tz(datetime, null);
    const pad = (number, length = 2) => {
        return number.toString().padStart(length, '0');
    };
    const dateBreakdown = {
        year: m.year(),
        month: pad(m.month() + 1),
        date: pad(m.date()),
        hour: pad(m.hour()),
        minute: pad(m.minute()),
        second: pad(m.second())
    };
    return `${dateBreakdown.year}-${dateBreakdown.month}-${dateBreakdown.date}T${dateBreakdown.hour}:${dateBreakdown.minute}:${dateBreakdown.second}`;
};

/**
 * Collection of `util` helper functions
 */

/**
 * Generate keys for React Components
 * @param {String} string Param that is used for the key
 * @return {String} Returns generated key
 */
const generateKey = string => {
    let theKey = '';

    // if FormattedMessage object
    if (string.constructor === Object) {
        if (string.props && string.props.defaultMessage) {
            string = string.props.defaultMessage;
        }
    }

    // generate key
    theKey = string
        .toString()
        .split('')
        .reduce(
            // eslint-disable-next-line
            (prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
            0
        );

    return theKey;
};

/**
 * Disables the ability to type characters, only numberic values allowed
 * @param {string} value
 * @return {string}
 */
const normalizeNumber = value => {
    const onlyNums = value.replace(/[^\d]/g, '');
    return onlyNums;
};

function formatAmPm(hours, minutes) {
    let h = hours;
    let m = minutes;
    const ampm = h >= 12 ? 'pm' : 'am';
    h %= 12;
    h = h || 12; // the hour '0' should be '12'
    m = m < 10 && m > 0 ? `0${m}` : m;
    const strTime = `${h}:${m} ${ampm}`;
    return strTime;
}

/**
 * Format date
 * @param {string} date
 * @param {string} format
 * @return {String} date
 * */
const formatDate = (date, format = 'DD-MM-YYYY') => {
    if (!date) {
        return null;
    }

    const d = new Date(date);
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    function appendZero(time) {
        if (time <= 9) {
            return `0${time}`;
        }
        return time;
    }

    const day = d.getDate();
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const monthString = months[month];
    const hour = d.getHours();
    const minute = d.getMinutes();
    const second = d.getSeconds();

    switch (format) {
        case 'YYYY-MM-DD':
            return `${year}-${appendZero(month)}-${appendZero(day)}`;
        case 'YYYY/MM/DD':
            return `${year}/${appendZero(month)}/${appendZero(day)}`;
        case 'DD/MM/YYYY':
            return `${appendZero(day)}/${appendZero(month)}/${year}`;
        case 'DD-MM-YYYY':
            return `${appendZero(day)}-${appendZero(month)}-${year}`;
        case 'Month':
            return monthString;
        case 'Day+Month':
            return `${day} ${monthString}`;
        case 'Month+Day':
            return `${monthString} ${day}`;
        case 'hh:mm:ss':
            return `${appendZero(hour)}:${appendZero(minute)}:${appendZero(second)}`;
        case 'hh:mm':
            return `${appendZero(hour)}:${appendZero(minute)}`;
        case 'hh:mm AA':
            return formatAmPm(hour, minute);
        case 'YYYY-MM-DD hh:mm:ss':
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        case 'YYYY-MM-DD hh:mm AA':
            return `${year}-${month}-${day} ${formatAmPm(hour, minute)}`;
        case 'YYYY/MM/DD hh:mm:ss':
            return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
        case `YYYY/MM/DD hh:mm AA`:
            return `${year}/${month}/${day} ${formatAmPm(hour, minute)}`;
        default:
            return `${day}-${month}-${year}`;
    }
};

/**
 * Formats currency
 * @return {String} currency in form of 55.555 or 55.555,00 ( if decimal )
 * */
const formatCurrency = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Turns Hex into rgba
 * @return {String} rgba
 * */
const hexToRGB = (hex, alpha, onlyValues) => {
    hex = hex.toString().replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    if (onlyValues) {
        return `${r},${g},${b}`;
    }

    if (alpha) {
        return `rgba(${r},${g},${b},${alpha})`;
    }

    return `rgb(${r},${g},${b})`;
};

/**
 * Returns Currency in K format
 * @return {String} number
 * */
const kFormatter = n => {
    if (n >= 1000) {
        return `${+(n / 1000).toFixed(1)}K`;
    }
    return n;
};

/**
 * Formatting an integer with a comma as a thousands separators
 * @param {string|int} number
 * @returns {String} properly formatted number.
 */
function formatNumber(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 * Parses a string and returns an integer
 * @param value {string|number}
 * @returns {integer}
 */
function parseInteger(value) {
    return Number.isNaN(parseInt(value, 10)) ? null : parseInt(value, 10);
}

/**
 * Access Object with string key as property path
 * @param object {Object}
 * @param path {string} String key
 * @return {*}
 */
const resolveObjectPathByString = (object, path) =>
    path
        .split(/[.[\]'"]/)
        .filter(p => p)
        .reduce((o, p) => (o ? o[p] : null), object);

// default spacing for app
const spacing = parseInt(grid?.spacing, 10);

/**
 * Check if you hit mobile resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifMobile = currentWidth => {
    return currentWidth < parseInt(breakpoints?.tablet, 10) + 1;
};

/**
 * Check if you hit tablet resolution
 * @param currentWidth {number}
 * @returns {boolean}
 */
const ifTablet = currentWidth => {
    return currentWidth < parseInt(breakpoints?.desktop, 10) + 1;
};

/**
 * Convert seconds to custom timelog
 * @param seconds
 * @returns {string}
 */
const timeValueConverter = seconds => {
    let timeFormat = '';
    if (seconds < 3600) {
        timeFormat = `${Math.round(seconds / 60)}m`;
    } else if (seconds === 3600) {
        timeFormat = '1h';
    } else if (seconds > 3600 && seconds <= 86400) {
        const hours = Math.floor(seconds / 60 / 60);
        const minutes = (seconds / 60) % 60;
        timeFormat = minutes === Number(0) ? `${hours}h` : `${hours}h ${minutes}m`;
    }
    return timeFormat;
};

export {
    dtoSortByPositionAndId,
    dtoIgnoreTimezone,
    generateKey,
    normalizeNumber,
    formatDate,
    formatAmPm,
    formatCurrency,
    hexToRGB,
    kFormatter,
    formatNumber,
    parseInteger,
    resolveObjectPathByString,
    spacing,
    ifMobile,
    ifTablet,
    breakpoints,
    timeValueConverter
};
