import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const menuId = 'main-navigation';
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    return (
        <Menu id={menuId}>
            <Menu.Item to="/dashboard" label="Dashboard" />
            {hasPermissionsAccessForEntities.users && <Menu.Item to="/users" label="Users" />}
            {hasPermissionsAccessForEntities.clients && <Menu.Item to="/clients" label="Clients" />}
            {hasPermissionsAccessForEntities.projects && (
                <Menu.Item to="/projects" label="Projects" />
            )}
            {hasPermissionsAccessForEntities.settings && (
                <Menu.Submenu label="Settings">
                    {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                        <Menu.SubmenuItem
                            to="/settings/organization/general"
                            label="Organization"
                        />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                        <Menu.SubmenuItem to="/settings/permissions" label="Permissions" />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Menu.SubmenuItem to="/settings/roles" label="Roles" />
                    )}
                </Menu.Submenu>
            )}
        </Menu>
    );
};

export default withRouter(MainNavigation);
