import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import myAccount from 'erpcore/screens/MyAccount/MyAccount.reducer';
import activityLog from 'erpcore/components/ActivityLog/ActivityLog.reducer';
import comments from 'erpcore/components/Comments/Comments.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
// Project Reducers - REMEMBER TO ORDER THEM ALPHABETICALLY //
import clients from 'erpcore/screens/Clients/Clients.reducer';
// import companyContacts from 'erpcore/screens/Companies/screens/CompanyContacts/CompanyContacts.reducer';
import contacts from 'erpcore/screens/Contacts/Contacts.reducer';
import {
    options,
    organization,
    permissions,
    roles
} from 'erpcore/screens/Settings/Settings.reducer'; // Settings
import employmentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import projects from 'erpcore/screens/Projects/Projects.reducer';
import { employeeRecordsReport } from 'erpcore/screens/Reports/Reports.reducer'; // Reports
import users from 'erpcore/screens/Users/Users.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    routerManager,
    layoutManager,
    notificationManager,
    activityLog,
    comments,
    listing,
    form,
    forgotPassword,
    imageManager,
    myAccount, // system reducers end
    clients,
    // companyContacts,
    contacts,
    employeeRecordsReport,
    employmentRecords,
    options,
    organization,
    permissions,
    projects,
    roles,
    users
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
