import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

// Screens / Pages
// System
import Error404 from 'erpcore/screens/Error404';
// Auth
import SignIn from 'erpcore/screens/SignIn';
import SignOut from 'erpcore/screens/SignOut';
import ForgotPassword from 'erpcore/screens/ForgotPassword/screens/ForgotPassword';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';
import ResetPasswordSignedIn from 'erpcore/screens/ForgotPassword/screens/ResetPasswordSignedIn';
// General
import Dashboard from 'erpcore/screens/Dashboard';
// My Account
import MyAccountPersonalDetails from 'erpcore/screens/MyAccount/screens/MyAccountPersonalDetails';
import MyAccountLinkedAccounts from 'erpcore/screens/MyAccount/screens/MyAccountLinkedAccounts';
// Clients
import ClientsListing from 'erpcore/screens/Clients/screens/ClientsListing';
import ClientCreate from 'erpcore/screens/Clients/screens/ClientCreate';
import ClientEdit from 'erpcore/screens/Clients/screens/ClientEdit';
// Projects
import ProjectsListing from 'erpcore/screens/Projects/screens/Projects';
import ProjectCreate from 'erpcore/screens/Projects/screens/ProjectCreate';
import ProjectEditGeneralInfo from 'erpcore/screens/Projects/screens/ProjectEdit/screens/GeneralInfo';
import ProjectEditBuildingDesign from 'erpcore/screens/Projects/screens/ProjectEdit/screens/BuildingDesign';
import ProjectEditNeighbourhood from 'erpcore/screens/Projects/screens/ProjectEdit/screens/Neighbourhood';
import ProjectEditFloorplans from 'erpcore/screens/Projects/screens/ProjectEdit/screens/Floorplans';
import ProjectEditHomeTour from 'erpcore/screens/Projects/screens/ProjectEdit/screens/HomeTour';
import ProjectEditFeatureAndOptions from 'erpcore/screens/Projects/screens/ProjectEdit/screens/FeatureAndOptions';
// Reports
import EmployeeRecordsReport from 'erpcore/screens/Reports/EmployeeRecords';
// Users
import UsersListing from 'erpcore/screens/Users/screens/UsersListing';
import UserCreate from 'erpcore/screens/Users/screens/UserCreate';
import UserEdit from 'erpcore/screens/Users/screens/UserEdit';
import UserEmploymentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords';
import UserPermissions from 'erpcore/screens/Users/screens/UserPermissions';
// Settings
import OrganizationGeneral from 'erpcore/screens/Settings/Organization/screens/OrganizationGeneral';
import OrganizationBranding from 'erpcore/screens/Settings/Organization/screens/OrganizationBranding';
import Permissions from 'erpcore/screens/Settings/Permissions';
import Roles from 'erpcore/screens/Settings/Roles/screens/Roles';
import RoleCreate from 'erpcore/screens/Settings/Roles/screens/RoleCreate';
import RoleEdit from 'erpcore/screens/Settings/Roles/screens/RoleEdit';

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const isSignedIn = useSelector(getSignedIn);
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the private routes when the user is signed in
    if (isSignedIn) {
        return (
            <Switch>
                <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                <Route exact path="/reset-password" component={ResetPasswordSignedIn} />
                <Route exact path="/reset-password/:token" component={ResetPasswordSignedIn} />
                <Route
                    key="ResetPasswordLogin"
                    path="/reset-password-login"
                    exact
                    component={ResetPassword}
                />
                <Route key="Dashboard" path="/dashboard" exact component={Dashboard} />
                {/* My Account */}
                <Route
                    key="MyAccountPersonalDetails"
                    path="/my-account"
                    exact
                    component={MyAccountPersonalDetails}
                />
                <Route
                    key="MyAccountLinkedAccounts"
                    path="/my-account/linked-accounts"
                    exact
                    component={MyAccountLinkedAccounts}
                />

                {/* Users */}
                {hasPermissionsAccessForEntities.users && (
                    <Route key="UsersListing" path="/users" exact component={UsersListing} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route key="UserCreate" path="/users/create" exact component={UserCreate} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route
                        key="UserEdit"
                        path="/users/:id/edit/details"
                        exact
                        component={UserEdit}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                    <Route
                        key="UserEmploymentRecords"
                        path="/users/:id/edit/employment-records"
                        exact
                        component={UserEmploymentRecords}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Route
                        key="UserPermissions"
                        path="/users/:id/edit/permissions"
                        exact
                        component={UserPermissions}
                    />
                )}

                {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                    <Route
                        key="OrganizationGeneral"
                        path="/settings/organization/general"
                        exact
                        component={OrganizationGeneral}
                    />
                )}

                {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                    <Route
                        key="OrganizationBranding"
                        path="/settings/organization/branding"
                        exact
                        component={OrganizationBranding}
                    />
                )}

                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route key="Roles" path="/settings/roles" exact component={Roles} />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route
                        key="RoleCreate"
                        path="/settings/roles/create"
                        exact
                        component={RoleCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                    <Route
                        key="RoleEdit"
                        path="/settings/roles/:id/edit"
                        exact
                        component={RoleEdit}
                    />
                )}

                {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                    <Route
                        key="Permissions"
                        path="/settings/permissions"
                        exact
                        component={Permissions}
                    />
                )}

                {/* Clients */}
                {hasPermissionsAccessForEntities.clients && (
                    <Route key="ClientsListing" path="/clients" exact component={ClientsListing} />
                )}
                {/* {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && ( */}
                <Route key="ClientCreate" path="/clients/create" exact component={ClientCreate} />
                {/* )} */}
                {/* {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && ( */}
                <Route
                    key="ClientEdit"
                    path="/clients/:id/edit/general-info"
                    exact
                    component={ClientEdit}
                />
                {/* )} */}

                {/* Projects */}
                {hasPermissionsAccessForEntities.projects && (
                    <Route key="Projects" path="/projects" exact component={ProjectsListing} />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectCreate"
                        path="/projects/create"
                        exact
                        component={ProjectCreate}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditGeneralInfo"
                        path="/projects/:id/edit/general-info"
                        exact
                        component={ProjectEditGeneralInfo}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditBuildingDesign"
                        path="/projects/:id/edit/building-design"
                        exact
                        component={ProjectEditBuildingDesign}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditNeighbourhood"
                        path="/projects/:id/edit/neighbourhood"
                        exact
                        component={ProjectEditNeighbourhood}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditFloorplans"
                        path="/projects/:id/edit/floorplans"
                        exact
                        component={ProjectEditFloorplans}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditHomeTour"
                        path="/projects/:id/edit/home-tour"
                        exact
                        component={ProjectEditHomeTour}
                    />
                )}
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Route
                        key="ProjectEditFeatureAndOptions"
                        path="/projects/:id/edit/feature-and-options"
                        exact
                        component={ProjectEditFeatureAndOptions}
                    />
                )}

                {/* Reports */}
                {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                    <Route
                        key="EmployeeRecordsReport"
                        path="/reports/employee-records"
                        exact
                        component={EmployeeRecordsReport}
                    />
                )}

                <Redirect exact from="/" to="/dashboard" />
                <Route component={Error404} />
            </Switch>
        );
    }

    // Render the  router when the user is not signed in
    return (
        <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/reset-password-login" component={ResetPassword} />
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location }
                }}
            />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
