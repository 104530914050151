import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import ClientCreateActionModal from 'erpcore/screens/Clients/components/ClientCreateActionModal';
import ClientEditActionModal from 'erpcore/screens/Clients/components/ClientEditActionModal';
import Form, {
    Text,
    Textarea,
    Autocomplete,
    Select,
    Location,
    Media,
    Repeater
} from 'erpcore/components/Form';
import PurchaseCalculatorsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/PurchaseCalculatorsRepeaterItem';
import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';

const ProjectForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    form
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Project name',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="client"
                    fieldProps={{
                        label: 'Client',
                        options: {
                            endpoint: '/api/clients',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true,
                        actionButton: {
                            edit: {
                                component: <ClientEditActionModal formName={form} />
                            },
                            create: {
                                component: <ClientCreateActionModal formName={form} />
                            }
                        }
                    }}
                    fieldAttr={{ id: 'client', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="is_active"
                    fieldProps={{
                        label: 'Is active',
                        clearable: true,
                        options: projectIsActive
                    }}
                    fieldAttr={{ id: 'isActive' }}
                    component={Select}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="headline"
                    fieldProps={{
                        label: 'Headline',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'headline' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="description"
                    fieldProps={{
                        label: 'Short description',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'description' }}
                    component={Textarea}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="homes"
                    fieldProps={{
                        label: 'Homes',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'homes' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="bedrooms"
                    fieldProps={{
                        label: 'Bedrooms',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'bedrooms' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_homes"
                    fieldProps={{
                        label: 'Number of homes',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'numberOfHomes' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="status"
                    fieldProps={{
                        label: 'Status',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'status' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="website"
                    fieldProps={{
                        label: 'Website',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'website' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="matterport_url"
                    fieldProps={{
                        label: 'Matterport URL',
                        clearable: true,
                        helperText: 'Example: https://my.matterport.com/show/?m=xxxxxxxxxxx'
                    }}
                    fieldAttr={{ id: 'matterport_url' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="intro_video_url"
                    fieldProps={{
                        label: 'YouTube URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'intro_video_url' }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Development project logo</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="development_project_logo"
                    id="development_project_logo"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Featured image</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="featured_image"
                    id="featured_image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Main gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="main_gallery"
                    id="main_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Purchase calculators</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="purchase_calculators"
                    component={Repeater}
                    RepeatableItem={PurchaseCalculatorsRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="location"
                    fieldProps={{
                        locationManualTogglelabel: 'Input address manually'
                    }}
                    fieldAttr={{ id: 'location' }}
                    component={Location}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    form: null
};

ProjectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    form: PropTypes.string
};

export default reduxForm({
    form: 'ProjectForm',
    enableReinitialize: true
})(ProjectForm);
