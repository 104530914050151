export const actions = {
    START_FETCHING_SINGLE_ORGANIZATION: 'START_FETCHING_SINGLE_ORGANIZATION',
    START_FETCHING_MINE_ORGANIZATION: 'START_FETCHING_MINE_ORGANIZATION',
    FETCH_SINGLE_ORGANIZATION_SUCCESSFUL: 'FETCH_SINGLE_ORGANIZATION_SUCCESSFUL',
    FETCH_SINGLE_ORGANIZATION_FAILED: 'FETCH_SINGLE_ORGANIZATION_FAILED',
    START_UPDATE_SINGLE_ORGANIZATION: 'START_UPDATE_SINGLE_ORGANIZATION',
    START_UPDATE_MINE_ORGANIZATION: 'START_UPDATE_MINE_ORGANIZATION',
    UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL: 'UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL',
    UPDATE_SINGLE_ORGANIZATION_FAILED: 'UPDATE_SINGLE_ORGANIZATION_FAILED',
    STORE_SINGLE_ORGANIZATION_DATA: 'STORE_SINGLE_ORGANIZATION_DATA',
    START_FETCHING_ORGANIZATION_SETTINGS: 'START_FETCHING_ORGANIZATION_SETTINGS',
    FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS: 'FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS',
    FETCHING_FAILED_ORGANIZATION_SETTINGS: 'FETCHING_FAILED_ORGANIZATION_SETTINGS',
    START_UPDATE_ORGANIZATION_SETTINGS: 'START_UPDATE_ORGANIZATION_SETTINGS',
    UPDATE_SUCCESSFUL_ORGANIZATION_SETTINGS: 'UPDATE_SUCCESSFUL_ORGANIZATION_SETTINGS',
    UPDATE_FAILED_ORGANIZATION_SETTINGS: 'UPDATE_FAILED_ORGANIZATION_SETTINGS',
    START_FETCHING_ORGANIZATION_SETTINGS_PUBLIC: 'START_FETCHING_ORGANIZATION_SETTINGS_PUBLIC',
    FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS_PUBLIC:
        'FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS_PUBLIC',
    FETCHING_FAILED_ORGANIZATION_SETTINGS_PUBLIC: 'FETCHING_FAILED_ORGANIZATION_SETTINGS_PUBLIC'
};

export const initialState = {
    singleFetching: false,
    singleError: null,
    fetchingOrganizationSettings: false,
    fetchingOrganizationSettingsPublic: false,
    organizationSettings: {}
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_SINGLE_ORGANIZATION:
        case actions.START_FETCHING_MINE_ORGANIZATION: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_ORGANIZATION_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_ORGANIZATION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_ORGANIZATION_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.START_FETCHING_ORGANIZATION_SETTINGS_PUBLIC: {
            return {
                ...state,
                fetchingOrganizationSettingsPublic: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS_PUBLIC: {
            return {
                ...state,
                fetchingOrganizationSettingsPublic: false,
                organizationSettings: response.data[0] || {}
            };
        }
        case actions.FETCHING_FAILED_ORGANIZATION_SETTINGS_PUBLIC: {
            return {
                ...state,
                fetchingOrganizationSettingsPublic: false,
                organizationSettings: response
            };
        }
        case actions.START_FETCHING_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false,
                organizationSettings: response.data[0] || {}
            };
        }
        case actions.FETCHING_FAILED_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false,
                organizationSettings: response
            };
        }
        case actions.START_UPDATE_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: true
            };
        }
        case actions.UPDATE_SUCCESSFUL_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false,
                organizationSettings: response.data
            };
        }
        case actions.UPDATE_FAILED_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingOrganizationSettings: false,
                organizationSettings: response
            };
        }
        default:
            return state;
    }
};
