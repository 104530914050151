import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import base64 from 'base-64';
import clone from 'lodash/clone';
import map from 'lodash/map';
import qs from 'qs';
import PropTypes from 'prop-types';
import ElementLoader from 'erpcore/components/ElementLoader';
import { BulkActionsDropdown } from './components/BulkActions';
import Table from './components/Table';
import Pagination from './components/Pagination';
import Filter from './components/Filter';
import FilterTags from './components/FilterTags';
import Search from './components/Search';
import ShowingResults from './components/ShowingResults';
import './Listing.scss';

/**
 * Listing
 */
const Listing = ({
    table,
    meta,
    loading,
    children,
    name,
    reducerName,
    hideHeader,
    hideFilters,
    hideSearch,
    hideBody,
    hideFooter,
    asideSpaceBetween,
    onListingConfigUpdate,
    initialFetch
}) => {
    const history = useHistory();
    const { location } = { ...history };
    const { search } = { ...location };
    const [mounted, setMounted] = useState(false);
    const [queryParams, setQueryParams] = useState({});

    const injectDefaultOrderByToAllParams = params => {
        //  If filters are not in URL bar create listing key and assign defaultSort
        if (table.defaultSort && !params[name]) {
            params[name] = {};
            params[name].order_by = { [table.defaultSort.sortable]: table.defaultSort.order };
        }
        return params;
    };

    const injectDefaultOrderByToListingParams = params => {
        //  Inject directly to listing params with name
        if (table.defaultSort && !params.order_by) {
            params.order_by = { [table?.defaultSort?.sortable]: table?.defaultSort?.order };
        }
        return params;
    };

    //  Get URL Parameters of all listings
    const getAllUrlParameters = () => {
        //  Substr beacuse of '?' in the begining of location search
        const urlParams = qs.parse(history.location.search.substr(1));
        const allDecodedParams = {};
        map(Object.keys(urlParams), listingName => {
            const listingParams = clone(urlParams[listingName]);
            //  decode all base 64 filters to object
            if (listingParams?.filter) {
                listingParams.filter = qs.parse(base64.decode(listingParams.filter));
            }
            allDecodedParams[listingName] = clone(listingParams);
        });
        return injectDefaultOrderByToAllParams(allDecodedParams);
    };

    //  Get URL Parameters for this listing
    const getUrlParametersByName = () => {
        //  Substr beacuse of '?' in the begining of location search
        const urlParams = getAllUrlParameters();
        //  Pass only if params for this listing exists
        return urlParams[name] || {};
    };

    //  Update URL parameters
    const pushUrlParams = params => {
        //  Getting all existing
        const allExistingParams = clone(getAllUrlParameters());
        const newParams = clone(params);
        const allEncodedParams = {};
        //  Replace existing params with new ones
        allExistingParams[name] = newParams;
        //  encoding filter keys to hide them in url bar
        map(Object.keys(allExistingParams), listingName => {
            const listingParams = clone(allExistingParams[listingName]);
            if (listingParams?.filter) {
                listingParams.filter = base64.encode(qs.stringify(listingParams.filter));
            }
            allEncodedParams[listingName] = clone(listingParams);
        });
        //  Pushing updated query params to url bar
        history.push({
            pathName: history.location.pathname,
            search: qs.stringify(allEncodedParams, { encodeValuesOnly: true }),
            state: {
                changedByListing: true
            }
        });
    };

    //  Clear query params of falsies
    const clearQueryParams = params => {
        const clearedParams = {};
        map(Object.keys(params), key => {
            if (
                (params[key] || params[key] === false) &&
                //  if param is empty object (beacuse {} === true but in this case is not)
                !(typeof params[key] === 'object' && Object.keys(params[key]).length === 0)
            ) {
                clearedParams[key] = params[key];
            }
        });
        return clearedParams;
    };

    //  Merge existing query params with new ones
    const combineListingParams = (params, mergeFilter = true) => {
        let combinedParams = {};
        const newParams = clone(params);
        const existingParams = getUrlParametersByName();
        //  merging filters
        if (existingParams?.filter && newParams?.filter && mergeFilter) {
            map(Object.keys(existingParams.filter), key => {
                if (!newParams.filter[key]) newParams.filter[key] = [];
                Array.prototype.push.apply(newParams.filter[key], existingParams.filter[key]);
            });
        }
        combinedParams = Object.assign(existingParams, newParams);
        return combinedParams;
    };

    useEffect(() => {
        const params = clearQueryParams(getUrlParametersByName());
        setQueryParams(params);
        if (initialFetch) {
            onListingConfigUpdate(params);
        }
        setMounted(true);
    }, []);

    const onChange = (params, mergeFilters = true) => {
        const derivatedParams = injectDefaultOrderByToListingParams(
            clearQueryParams(combineListingParams(params, mergeFilters))
        );
        setQueryParams(derivatedParams);
        onListingConfigUpdate(derivatedParams);
        pushUrlParams(derivatedParams);
    };

    // Refetch listing if URL query params are cleared
    // Example: click on the main navigation link
    useEffect(() => {
        if (mounted && !search) {
            const params = clearQueryParams(getUrlParametersByName());
            onListingConfigUpdate(params);
        }
    }, [search]);

    return (
        <div className="listing">
            {loading === true && <ElementLoader overlay />}
            {hideHeader === false && (
                <div className="listing__header">
                    {(!hideFilters || !hideSearch) && (
                        <div className="listing__header-col listing__header-col--main">
                            {hideFilters === false && table.filters && (
                                <div className="listing__header-col listing__header-col--filter">
                                    <Filter
                                        form={`Filter${name}`}
                                        onSubmit={filterData => {
                                            //  Removing unneccesary filterData
                                            delete filterData.filterBy;
                                            //  Restarting pagination to 1
                                            filterData.page = 1;
                                            onChange(filterData);
                                        }}
                                        filterSchema={table.filters}
                                    />
                                </div>
                            )}
                            {hideSearch === false && (
                                <div className="listing__header-col listing__header-col--search">
                                    <Search queryParams={queryParams} onChangeSearch={onChange} />
                                </div>
                            )}
                        </div>
                    )}
                    {(table.bulkActions || children) && (
                        <div
                            className={`listing__header-col listing__header-col--aside ${
                                asideSpaceBetween ? 'listing__header-col--space-between' : ''
                            }`}
                        >
                            <BulkActionsDropdown
                                reducerName={reducerName}
                                bulkActionsData={table.bulkActions}
                            />
                            {children}
                        </div>
                    )}
                </div>
            )}
            <FilterTags
                filterSchema={table.filters}
                onChangeFilterTag={filters => onChange(filters, false)}
                queryParams={queryParams}
            />
            {!hideBody && (
                <div className="listing__body">
                    <Table
                        name={name}
                        reducerName={reducerName}
                        data={table}
                        onSortTable={onChange}
                        queryParams={queryParams}
                        loading={loading}
                    />
                </div>
            )}
            {hideFooter === false && (
                <>
                    {meta?.totalItems >= 10 && (
                        <div className="listing__footer">
                            <ShowingResults meta={meta} onChangeResultsPerPage={onChange} />
                            <Pagination meta={meta} onChangePagination={onChange} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

Listing.defaultProps = {
    hideHeader: false,
    hideSearch: false,
    hideFilters: false,
    hideBody: false,
    hideFooter: false,
    table: {
        data: [],
        schema: [],
        filters: []
    },
    meta: {},
    onListingConfigUpdate: () => {},
    initialFetch: true,
    loading: false,
    children: null,
    asideSpaceBetween: false
};

Listing.propTypes = {
    name: PropTypes.string.isRequired,
    reducerName: PropTypes.string.isRequired,
    hideHeader: PropTypes.bool,
    hideSearch: PropTypes.bool,
    hideFilters: PropTypes.bool,
    hideBody: PropTypes.bool,
    hideFooter: PropTypes.bool,
    table: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    onListingConfigUpdate: PropTypes.func,
    initialFetch: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    asideSpaceBetween: PropTypes.bool
};

export default Listing;
