import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Email, GeneratePassword, Text, CheckboxGroup, Media } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';

const UserCreateForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    roles
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitle>Personal details</Form.SectionTitle>
            <Form.Columns>
                <Form.Column>
                    <Form.Row>
                        <Field
                            name="first_name"
                            id="first_name"
                            fieldProps={{
                                label: 'First name',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="last_name"
                            id="last_name"
                            fieldProps={{
                                label: 'Last name',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="email"
                            id="email"
                            fieldProps={{
                                label: 'Email',
                                clearable: true
                            }}
                            fieldAttr={{ required: true }}
                            component={Email}
                            validate={valueValidation([
                                { validator: 'email' },
                                { validator: 'required' }
                            ])}
                        />
                    </Form.Row>
                </Form.Column>

                <Form.Column>
                    <Form.SectionTitleSmall>Profile photo</Form.SectionTitleSmall>
                    <Form.Row>
                        <Field
                            name="image"
                            id="image"
                            component={Media}
                            useLightbox
                            useImageManager
                            allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                        />
                    </Form.Row>
                </Form.Column>
            </Form.Columns>
            <Form.Row>
                <Field
                    name="roles"
                    id="roles"
                    fieldProps={{
                        label: 'Roles',
                        options: roles
                    }}
                    fieldAttr={{ required: true }}
                    component={CheckboxGroup}
                />
                <p>
                    Roles are buckets of permissions, user can have multiple roles assigned in which
                    case they will have all permissions assigned from combined roles.
                </p>
            </Form.Row>
            <Form.SectionTitleSmall>Password</Form.SectionTitleSmall>
            <Form.Row>
                <GeneratePassword name="password" passwordLabel="Password" />
            </Form.Row>

            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

UserCreateForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Create',
    pristine: false,
    invalid: false,
    roles: []
};

UserCreateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    roles: PropTypes.oneOfType([PropTypes.array])
};

export default reduxForm({
    form: 'UserForm',
    enableReinitialize: true,
    initialValues: {
        is_active: true,
        _type: 'staff'
    }
})(UserCreateForm);
