import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, reset } from 'redux-form';

import Form, {
    DateTime,
    Email,
    Phone,
    RadioGroup,
    Select,
    Text,
    Location,
    Media
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import UserChangePasswordForm from 'erpcore/screens/Users/components/UserChangePasswordForm';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { gender, userActiveStatus, userType } from 'erpcore/screens/Users/data/users';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import moment from 'moment-timezone';

const UserEditForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    userId,
    initialValues
}) => {
    const formName = 'UserChangePasswordForm';
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = (newOpenState = null) => {
        if (newOpenState === null) {
            setModalOpened(!modalOpened);
        } else {
            setModalOpened(newOpenState);
        }
    };

    const onSubmitPasswordChange = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CHANGE_PASSWORD_SINGLE_USER,
                id: userId,
                formData
            });
        })
            .then(() => {
                handleModal(false);
            })
            .catch(() => {
                dispatch(reset(formName));
            });
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.SectionTitle>Personal Details</Form.SectionTitle>
                {initialValues?.lastLoggedIn && (
                    <p>
                        User last logged in:{' '}
                        {moment(initialValues.lastLoggedIn).format('MMM Do, YYYY h:mm a')}
                    </p>
                )}
                <Form.Row>
                    <Field
                        name="first_name"
                        id="first_name"
                        fieldProps={{
                            label: 'First name',
                            clearable: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="last_name"
                        id="last_name"
                        fieldProps={{
                            label: 'Last name',
                            clearable: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="email"
                        id="email"
                        fieldProps={{
                            label: 'Email',
                            clearable: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Email}
                        validate={valueValidation([
                            { validator: 'email' },
                            { validator: 'required' }
                        ])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="phone_number"
                        id="phone_number"
                        fieldProps={{
                            label: 'Phone'
                        }}
                        component={Phone}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="is_active"
                        id="is_active"
                        fieldProps={{
                            label: 'Status',
                            clearable: false,
                            options: userActiveStatus
                        }}
                        component={Select}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="birthdate"
                        id="birthdate"
                        fieldProps={{
                            label: 'Birth date',
                            showTimeSelect: false,
                            clearable: true,
                            icon: 'date',
                            iconPosition: 'right'
                        }}
                        component={DateTime}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="location"
                        id="location"
                        fieldProps={{
                            locationManualTogglelabel: 'Input address manually'
                            // showLocationDetails: true
                        }}
                        // fieldAttr={{ disabled: true }}
                        component={Location}
                    />
                </Form.Row>
                <Form.Columns>
                    <Form.Column>
                        <Form.SectionTitleSmall>Profile photo</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="image"
                                id="image"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.Row>
                            <Form.SectionTitleSmall>Gender</Form.SectionTitleSmall>
                            <Field
                                name="gender"
                                id="gender"
                                fieldProps={{
                                    options: gender
                                }}
                                component={RadioGroup}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Form.SectionTitleSmall>Type</Form.SectionTitleSmall>
                            <Field
                                name="_type"
                                id="_type"
                                fieldProps={{
                                    options: userType
                                }}
                                component={RadioGroup}
                            />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>

                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
                <Button
                    onClick={() => handleModal()}
                    variation="secondary"
                    label="Change password"
                />
            </Form>

            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Change Password"
            >
                <UserChangePasswordForm
                    onSubmit={onSubmitPasswordChange}
                    form={formName}
                    submitLabel="Update Password"
                />
            </Modal>
        </>
    );
};

UserEditForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    userId: null,
    pristine: false,
    invalid: false,
    initialValues: {}
};

UserEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    form: 'UserForm',
    enableReinitialize: true
})(UserEditForm);
