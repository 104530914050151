import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
// import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { Repeater, Media } from 'erpcore/components/Form';
import TitleGalleryRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleGalleryRepeaterItem';
import TitleDescriptionRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleDescriptionRepeaterItem';
import ArchitecturalPlansRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/ArchitecturalPlansRepeaterItem';

const BuildingDesignForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleSmall>Exterior rendering</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="exterior_rendering_images"
                    id="exterior_rendering_images"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Site plan images</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="site_plan_images"
                    component={Repeater}
                    RepeatableItem={TitleGalleryRepeaterItem}
                    data={{
                        mediaTitle: 'Images'
                    }}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.SectionTitleSmall>Amenity programming features</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="amenity_programming_features"
                    component={Repeater}
                    RepeatableItem={TitleDescriptionRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.SectionTitleSmall>Amenity programming gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="amenity_programming_gallery"
                    id="amenity_programming_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Architectural plans</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="architectural_plans"
                    component={Repeater}
                    RepeatableItem={ArchitecturalPlansRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="iri"
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

BuildingDesignForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

BuildingDesignForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'BuildingDesignForm',
    enableReinitialize: true
})(BuildingDesignForm);
