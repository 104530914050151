const projectIsActive = [
    {
        label: 'Active',
        value: true
    },
    {
        label: 'Inactive',
        value: false
    }
];

const placeholder = null;

export { placeholder, projectIsActive };
