import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import BuildingDesignForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/BuildingDesignForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';

const ProjectEdit = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const {
        site_plan_images: sitePlanImages = [],
        amenity_programming_features: amenityProgrammingFeatures = []
    } = { ...projectData };

    const initialValues = {
        exterior_rendering_images: projectData.exterior_rendering_images,
        site_plan_images: sitePlanImages?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        amenity_programming_features: amenityProgrammingFeatures?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier1-${i}` };
        }),
        amenity_programming_gallery: projectData.amenity_programming_gallery,
        architectural_plans: projectData.architectural_plans
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data (except main_gallery)
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const {
            site_plan_images: sitePlanImagesDiff,
            exterior_rendering_images: exteriorRenderingImages,
            amenity_programming_features: amenityProgrammingFeaturesDiff,
            amenity_programming_gallery: amenityProgrammingGallery,
            architectural_plans: architecturalPlans
        } = { ...formDataDiff };
        if (exteriorRenderingImages) {
            formDataDiff.exterior_rendering_images = formData.exterior_rendering_images;
        }
        if (sitePlanImagesDiff) {
            formDataDiff.site_plan_images = cleanRepeaterOutput(formData.site_plan_images, [
                'customIdentifier'
            ]);
        }
        if (amenityProgrammingFeaturesDiff) {
            formDataDiff.amenity_programming_features = cleanRepeaterOutput(
                formData.amenity_programming_features,
                ['customIdentifier1']
            );
        }
        if (amenityProgrammingGallery) {
            formDataDiff.amenity_programming_gallery = formData.amenity_programming_gallery;
        }
        if (architecturalPlans) {
            formDataDiff.architectural_plans = cleanRepeaterOutput(formData.architectural_plans);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <BuildingDesignForm
                    onSubmit={onSubmit}
                    form={`BuildingDesignForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEdit.defaultProps = {
    match: {}
};

ProjectEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEdit);
