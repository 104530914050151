export const actions = {
    UPDATE_SIDEBAR_DECK_STATE: 'UPDATE_SIDEBAR_DECK_STATE',
    START_FETCHING_PUBLIC_ORGANIZATION_SETTINGS: 'START_FETCHING_PUBLIC_ORGANIZATION_SETTINGS',
    FETCHING_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS:
        'FETCHING_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS',
    FETCHING_FAILED_PUBLIC_ORGANIZATION_SETTINGS: 'FETCHING_FAILED_PUBLIC_ORGANIZATION_SETTINGS',
    START_UPDATE_PUBLIC_ORGANIZATION_SETTINGS: 'START_UPDATE_PUBLIC_ORGANIZATION_SETTINGS',
    UPDATE_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS:
        'UPDATE_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS',
    UPDATE_FAILED_PUBLIC_ORGANIZATION_SETTINGS: 'UPDATE_FAILED_PUBLIC_ORGANIZATION_SETTINGS'
};

export const initialState = {
    showDeck: true,
    fetchingPublicOrganizationSettings: false,
    publicOrganizationSettings: {}
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.UPDATE_SIDEBAR_DECK_STATE: {
            return {
                ...state,
                showDeck: response
            };
        }
        case actions.START_FETCHING_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: false,
                publicOrganizationSettings: response
            };
        }
        case actions.FETCHING_FAILED_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: false,
                publicOrganizationSettings: response
            };
        }
        case actions.START_UPDATE_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: true
            };
        }
        case actions.UPDATE_SUCCESSFUL_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: false,
                publicOrganizationSettings: response
            };
        }
        case actions.UPDATE_FAILED_PUBLIC_ORGANIZATION_SETTINGS: {
            return {
                ...state,
                fetchingPublicOrganizationSettings: false,
                publicOrganizationSettings: response
            };
        }
        default:
            return state;
    }
};
