import eas from 'erpcore/assets/svg/eas.svg';
import approve from 'erpcore/assets/svg/approve.svg';
import cancel from 'erpcore/assets/svg/cancel.svg';
import chat from 'erpcore/assets/svg/chat.svg';
import easPowered from 'erpcore/assets/svg/easPowered.svg';
import crop from 'erpcore/assets/svg/crop.svg';
import dashboard from 'erpcore/assets/svg/dashboard.svg';
import date from 'erpcore/assets/svg/date.svg';
import dollar from 'erpcore/assets/svg/dollar.svg';
import drag from 'erpcore/assets/svg/drag.svg';
import download from 'erpcore/assets/svg/download.svg';
import globe from 'erpcore/assets/svg/globe.svg';
import reject from 'erpcore/assets/svg/reject.svg';
import remove from 'erpcore/assets/svg/remove.svg';
import edit from 'erpcore/assets/svg/edit.svg';
import editSquare from 'erpcore/assets/svg/editSquare.svg';
import favorites from 'erpcore/assets/svg/favorites.svg';
import favoritesFull from 'erpcore/assets/svg/favoritesFull.svg';
import filter from 'erpcore/assets/svg/filter.svg';
import flipHorizontal from 'erpcore/assets/svg/flipHorizontal.svg';
import flipVertical from 'erpcore/assets/svg/flipVertical.svg';
import group from 'erpcore/assets/svg/group.svg';
import help from 'erpcore/assets/svg/help.svg';
import link from 'erpcore/assets/svg/link.svg';
import linkExternal from 'erpcore/assets/svg/linkExternal.svg';
import location from 'erpcore/assets/svg/location.svg';
import lock from 'erpcore/assets/svg/lock.svg';
import locked from 'erpcore/assets/svg/locked.svg';
import logo from 'erpcore/assets/svg/logo.svg';
import mail from 'erpcore/assets/svg/mail.svg';
import menu from 'erpcore/assets/svg/menu.svg';
import minus from 'erpcore/assets/svg/minus.svg';
import minusCircle from 'erpcore/assets/svg/minusCircle.svg';
import minusRound from 'erpcore/assets/svg/minusRound.svg';
import minusScale from 'erpcore/assets/svg/minusScale.svg';
import move from 'erpcore/assets/svg/move.svg';
import notificationError from 'erpcore/assets/svg/notificationError.svg';
import notificationSuccess from 'erpcore/assets/svg/notificationSuccess.svg';
import notificationWarning from 'erpcore/assets/svg/notificationWarning.svg';
import passHide from 'erpcore/assets/svg/passHide.svg';
import passShow from 'erpcore/assets/svg/passShow.svg';
import percent from 'erpcore/assets/svg/percent.svg';
import plus from 'erpcore/assets/svg/plus.svg';
import plusRound from 'erpcore/assets/svg/plusRound.svg';
import plusScale from 'erpcore/assets/svg/plusScale.svg';
import profile from 'erpcore/assets/svg/profile.svg';
import rotateLeft from 'erpcore/assets/svg/rotateLeft.svg';
import rotateRight from 'erpcore/assets/svg/rotateRight.svg';
import signOut from 'erpcore/assets/svg/signOut.svg';
import search from 'erpcore/assets/svg/search.svg';
import settings from 'erpcore/assets/svg/settings.svg';
import sorting from 'erpcore/assets/svg/sorting.svg';
import statistics from 'erpcore/assets/svg/statistics.svg';
import time from 'erpcore/assets/svg/time.svg';
import upload from 'erpcore/assets/svg/upload.svg';
import elementLoader from 'erpcore/assets/svg/elementLoader.svg';
import close from 'erpcore/assets/svg/close.svg';
import arrowDown from 'erpcore/assets/svg/arrowDown.svg';
import arrowLeft from 'erpcore/assets/svg/arrowLeft.svg';
import arrowRight from 'erpcore/assets/svg/arrowRight.svg';
import checkmark from 'erpcore/assets/svg/checkmark.svg';
import action from 'erpcore/assets/svg/action.svg';
import notFound from 'erpcore/assets/svg/notFound.svg';
import file from 'erpcore/assets/svg/file.svg';
import arrowDownRight from 'erpcore/assets/svg/arrowDownRight.svg';

export default {
    approve,
    eas,
    easPowered,
    cancel,
    crop,
    chat,
    date,
    dollar,
    drag,
    dashboard,
    download,
    globe,
    reject,
    remove,
    edit,
    editSquare,
    favorites,
    favoritesFull,
    filter,
    flipHorizontal,
    flipVertical,
    group,
    help,
    link,
    linkExternal,
    location,
    lock,
    locked,
    logo,
    mail,
    menu,
    minus,
    minusCircle,
    minusRound,
    minusScale,
    move,
    notificationError,
    notificationSuccess,
    notificationWarning,
    percent,
    profile,
    passHide,
    passShow,
    plus,
    plusRound,
    plusScale,
    rotateLeft,
    rotateRight,
    signOut,
    search,
    settings,
    sorting,
    statistics,
    time,
    upload,
    elementLoader,
    close,
    arrowDown,
    arrowLeft,
    arrowRight,
    checkmark,
    action,
    notFound,
    file,
    arrowDownRight
};
