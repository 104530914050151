import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { setToken, clearToken } from 'erpcore/api';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as authActions } from './AuthManager.reducer';

/**
 * /me enpoint params
 */
const meParams = {
    include: 'organization,organizations,image,image.versions,country,state,city'
};

/**
 * Sign In
 * @param  {Object} credentials Email and Password
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* signIn({ credentials, promise }) {
    try {
        const singIn = yield restClient.post('/login', credentials);
        // store token
        yield setToken(singIn?.data?.token);

        yield put({
            promise,
            type: authActions.START_FETCHING_ME
        });

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: singIn?.data?.token
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        // yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Into App With Token
 * @param  {string} token
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* signInWithToken({ token, promise }) {
    try {
        // store token
        yield setToken(token);

        yield put({
            type: authActions.SIGN_IN_SUCCESSFUL,
            response: token
        });

        yield put({
            promise,
            type: authActions.START_FETCHING_ME
        });

        // If any previous notification exists, remove it
        yield put({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.SIGN_IN_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Sign Out Saga
 */
export function* signOut() {
    try {
        clearToken();
        // This action is handled in main reducer file where it resets the redux store
        yield put({ type: authActions.SIGN_OUT_SUCCESSFUL });
    } catch (error) {
        yield console.error(error);
    }
}

/**
 * Fetch User Data
 * @param  {Object} promise Promises
 * @return {Object} Response from API
 */
export function* fetchMe({ promise }) {
    // Get user data
    try {
        const getMe = yield restClient.get('/api/me', { params: meParams });
        yield put({
            type: authActions.FETCHING_ME_SUCCESSFULL
        });

        // Fetch My Permissions
        yield put({
            type: authActions.START_FETCHING_ME_PERMISSIONS
        });

        try {
            const getMePermissions = yield restClient.get('/api/me/permissions');

            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_SUCCESSFULL
            });

            getMe.data.data.attributes.userPermissions =
                getMePermissions?.data?.data?.attributes?.permissions || [];

            yield put({ type: authActions.STORE_USER_DATA, response: dto(getMe?.data) });

            yield call(promise.resolve);
        } catch (error) {
            yield put({
                type: authActions.FETCHING_ME_PERMISSIONS_FAILED
            });
            yield put({
                type: notificationManagerActions.SET_PAGE_NOTIFICATION,
                response: error?.response?.data || error
            });
            yield call(promise.reject, error?.response?.data || error);
        }

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: authActions.FETCHING_ME_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const authManagerSaga = [
    takeLatest(authActions.START_SIGN_IN, signIn),
    takeLatest(authActions.START_SIGN_IN_WITH_TOKEN, signInWithToken),
    takeLatest(authActions.START_SIGN_OUT, signOut),
    takeLatest(authActions.START_FETCHING_ME, fetchMe)
];
