import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import FeatureAndOptionsForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/FeatureAndOptionsForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const FeatureAndOptions = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const initialValues = {
        features_gallery: projectData.features_gallery,
        home_tour_color_board: projectData.home_tour_color_board,
        features_list: projectData.features_list?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        options_gallery: projectData.options_gallery
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const {
            features_list: featuresList,
            options_gallery: optionsGallery,
            features_gallery: featuresGallery,
            home_tour_color_board: homeTourColorBoard
        } = {
            ...formDataDiff
        };
        if (featuresGallery) {
            formDataDiff.features_gallery = formData.features_gallery;
        }
        if (featuresList) {
            formDataDiff.features_list = cleanRepeaterOutput(formData.features_list, [
                'customIdentifier'
            ]);
        }
        if (optionsGallery) {
            formDataDiff.options_gallery = formData.options_gallery;
        }
        if (homeTourColorBoard) {
            formDataDiff.home_tour_color_board = formData.home_tour_color_board;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <FeatureAndOptionsForm
                    onSubmit={onSubmit}
                    form={`FeatureAndOptionsForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

FeatureAndOptions.defaultProps = {
    match: {}
};

FeatureAndOptions.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(FeatureAndOptions);
