import { createSelector } from 'reselect';

export const getOrganizationData = (state, iri) => state.organization?.[iri];

export const getMineOrganizationData = state =>
    state.organization?.[state?.authManager?.userOrganization?.iri];

export const getOrganizationFetching = state => state.organization?.singleFetching;

export const getOrganizationCurrencyData = (state, organizationIri = null) =>
    organizationIri
        ? state.organization?.[organizationIri]?.currency || null
        : state.organization?.[state?.authManager?.user?.organization?.iri]?.currency || null;

export const getOrganizationSettings = state => state?.organization?.organizationSettings;

export const getFetchingOrganizationSettings = state =>
    state?.organization?.fetchingOrganizationSettings;

export const getFetchingOrganizationSettingsPublic = state =>
    state?.organization?.fetchingOrganizationSettingsPublic;

export default createSelector([
    getOrganizationData,
    getOrganizationFetching,
    getOrganizationCurrencyData,
    getOrganizationSettings,
    getFetchingOrganizationSettings,
    getFetchingOrganizationSettingsPublic
]);
