import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import NeighbourhoodForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/NeighbourhoodForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';

const Neighbourhood = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const initialValues = {
        neighbourhood_gallery: projectData.neighbourhood_gallery,
        neighbourhood_google_maps: projectData.neighbourhood_google_maps,
        neighbourhood_google_street: projectData.neighbourhood_google_street
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const { neighbourhood_gallery: neighbourhoodGallery } = { ...formDataDiff };
        if (neighbourhoodGallery) {
            formDataDiff.neighbourhood_gallery = formData.neighbourhood_gallery;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <NeighbourhoodForm
                    onSubmit={onSubmit}
                    form={`NeighbourhoodForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

Neighbourhood.defaultProps = {
    match: {}
};

Neighbourhood.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(Neighbourhood);
