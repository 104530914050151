import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';

import Accordion from 'erpcore/components/Accordion';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import EmploymentHistoryRecord from 'erpcore/screens/Users/components/EmploymentHistoryRecord';
import Modal from 'erpcore/components/Modal';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as employmentRecordsActions } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import {
    getEmploymentRecordsListingFetching,
    getEmploymentRecordsPerUser
} from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.selectors';

const ViewEmployeeHistory = ({ data }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const { iri, id, first_name: firstName, last_name: lastName } = { ...data };
    const userEmploymentRecords = useSelector(state => getEmploymentRecordsPerUser(state, iri));
    const userEmploymentRecordsFetching = useSelector(state =>
        getEmploymentRecordsListingFetching(state)
    );

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const fetchUserEmploymentRecords = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: employmentRecordsActions.START_FETCHING_EMPLOYMENT_RECORDS_PER_USER,
                userIri: iri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        if (modalOpened) {
            fetchUserEmploymentRecords();
        }
    }, [modalOpened]);

    return (
        <>
            <Tooltip content="View employee history">
                <Button
                    variation="table-action"
                    iconName="passShow"
                    label=""
                    onClick={() => handleModal()}
                />
            </Tooltip>
            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Employee history"
            >
                {userEmploymentRecordsFetching && <ElementLoader overlay />}

                <p>
                    <NavLink to={`/users/${id}/edit/employment-records`} className="link-with-icon">
                        {firstName || ''} {lastName || ''} <Svg icon="editSquare" />
                    </NavLink>
                </p>

                {!!userEmploymentRecords?.data?.length && (
                    <Accordion variation="grey">
                        {userEmploymentRecords?.data.map(item => {
                            const {
                                iri: itemIri,
                                start_date: startDateTemp,
                                end_date: endDateTemp,
                                job_title: jobTitle,
                                employment_type: employmentType,
                                salary_type: salaryType,
                                salary_payout_cycle: salaryPayoutCycle,
                                reports_to: reportsTo,
                                comment,
                                contract
                            } = { ...item };

                            const startDate = dtoIgnoreTimezone(startDateTemp);
                            const endDate = dtoIgnoreTimezone(endDateTemp);

                            const title = `${moment(startDate).format('Do MMM YYYY')}${
                                endDate ? ` - ${moment(endDate).format('Do MMM YYYY')}` : ''
                            }${jobTitle?.name ? `, ${jobTitle?.name}` : ''}`;

                            return (
                                <Accordion.Item
                                    key={itemIri}
                                    title={title}
                                    variation="thin"
                                    content={
                                        <EmploymentHistoryRecord
                                            jobTitle={jobTitle}
                                            employmentType={employmentType}
                                            salary={item?.salary}
                                            salaryType={salaryType}
                                            salaryPayoutCycle={salaryPayoutCycle}
                                            office={item?.office}
                                            division={item?.division}
                                            department={item?.department}
                                            reportsTo={reportsTo}
                                            comment={comment}
                                            contract={contract}
                                        />
                                    }
                                />
                            );
                        })}
                    </Accordion>
                )}
            </Modal>
        </>
    );
};

ViewEmployeeHistory.defaultProps = {
    data: {}
};

ViewEmployeeHistory.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ViewEmployeeHistory;
