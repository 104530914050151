import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import RoleDelete from 'erpcore/screens/Settings/Roles/components/RoleDelete';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const Roles = () => {
    const reducerName = 'roles';
    const actionName = 'ROLES';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Roles';

    const fetchRoles = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/roles'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Role name',
                field: 'name',
                sortable: false
            },
            {
                title: '# of users',
                field: 'userCount',
                sortable: false
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];
        // Table Data
        if (listing && listing.data) {
            listing.data.map(row => {
                return table.data.push({
                    id: row.id,
                    iri: row.iri,
                    name: row.name,
                    userCount: row.user_count,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit role">
                                    <NavLink to={`/settings/roles/${row.id}/edit`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <RoleDelete roleData={row} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/settings/roles/create" label="Add new" />
            </PageHeader>

            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchRoles(params)}
            />
        </LayoutManager>
    );
};

export default Roles;
