import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword/ForgotPassword.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import { myAccountSaga } from 'erpcore/screens/MyAccount/MyAccount.saga';
import activityLogSaga from 'erpcore/components/ActivityLog/ActivityLog.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { commentsSaga } from 'erpcore/components/Comments/Comments.saga';
import clientsSaga from 'erpcore/screens/Clients/Clients.saga';
// import { companyContactsSaga } from 'erpcore/screens/Companies/screens/CompanyContacts/CompanyContacts.saga';
import { contactsSaga } from 'erpcore/screens/Contacts/Contacts.saga';
import {
    optionsSaga,
    organizationSaga,
    permissionsSaga,
    rolesSaga
} from 'erpcore/screens/Settings/Settings.saga';
import { employmentRecordsSaga } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.saga';
import projectsSaga from 'erpcore/screens/Projects/Projects.saga';
import { employeeRecordsReportSaga } from 'erpcore/screens/Reports/Reports.saga';
import { usersSaga } from 'erpcore/screens/Users/Users.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...forgotPasswordSaga]);
    yield all([...imageManagerSaga]);
    yield all([...myAccountSaga]);
    yield all([...activityLogSaga]);
    yield all([...listingSaga]);
    yield all([...commentsSaga]);
    yield all([...clientsSaga]);
    // yield all([...companyContactsSaga]);
    yield all([...contactsSaga]);
    yield all([...employeeRecordsReportSaga]);
    yield all([...employmentRecordsSaga]);
    yield all([...optionsSaga]);
    yield all([...organizationSaga]);
    yield all([...permissionsSaga]);
    yield all([...projectsSaga]);
    yield all([...rolesSaga]);
    yield all([...usersSaga]);
}
