import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
// import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { Media, Textarea } from 'erpcore/components/Form';

const NeighbourhoodForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleSmall>Gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="neighbourhood_gallery"
                    id="neighbourhood_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="neighbourhood_google_maps"
                    fieldProps={{
                        label: 'Google Maps',
                        clearable: true,
                        helperText: 'Copy embed code here'
                    }}
                    fieldAttr={{ id: 'neighbourhood_google_maps' }}
                    component={Textarea}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="neighbourhood_google_street"
                    fieldProps={{
                        label: 'Google Street View',
                        clearable: true,
                        helperText: 'Copy embed code here'
                    }}
                    fieldAttr={{ id: 'neighbourhood_google_street' }}
                    component={Textarea}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

NeighbourhoodForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

NeighbourhoodForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'NeighbourhoodForm',
    enableReinitialize: true
})(NeighbourhoodForm);
