import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';

/**
 * CurrencyFormatter
 * is used for formating prices
 */
const CurrencyFormatter = ({ amount, currency, currencyPosition, showCurrency, showSymbol }) => {
    const symbol = getSymbolFromCurrency(currency);

    const currencyPrefixSuffix = {};
    if (currencyPosition === 'right') {
        currencyPrefixSuffix.suffix = currency
            ? `${showCurrency ? ` ${currency}` : ''}${showSymbol ? ` ${symbol}` : ''}`
            : false;
    } else {
        currencyPrefixSuffix.prefix = currency
            ? `${showCurrency ? `${currency} ` : ''}${showSymbol ? `${symbol} ` : ''}`
            : false;
    }

    return (
        <NumberFormat
            allowEmptyFormatting={false}
            value={amount}
            isNumericString={false}
            thousandSeparator
            decimalSeparator="."
            fixedDecimalScale
            decimalScale={2}
            displayType="text"
            {...currencyPrefixSuffix}
        />
    );
};

CurrencyFormatter.defaultProps = {
    amount: 0,
    currency: '',
    currencyPosition: 'left',
    showCurrency: true,
    showSymbol: false
};

CurrencyFormatter.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
    currencyPosition: PropTypes.oneOf(['left', 'right']),
    showCurrency: PropTypes.bool,
    showSymbol: PropTypes.bool
};

export default CurrencyFormatter;
