import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ProjectDelete from 'erpcore/screens/Projects/components/ProjectDelete';
import StatusCircle from 'erpcore/components/StatusCircle';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';

import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';

const Projects = () => {
    const reducerName = 'projects';
    const actionName = 'PROJECTS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Projects';

    const fetchProjects = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/projects?include=client'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'client',
                label: 'Client',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Client',
                            options: {
                                endpoint: `/api/clients`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'is_active',
                label: 'Is active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Project',
                field: 'name',
                sortable: 'name'
            },
            {
                title: 'Client',
                field: 'client',
                sortable: false
            },
            {
                title: 'Is active',
                field: 'isActive',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        /*
        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };
        */

        // Table Data
        if (listing && listing.data) {
            listing.data.map(row => {
                const { id, iri, name, client, is_active: isActive } = {
                    ...row
                };

                return table.data.push({
                    id,
                    iri,
                    name,
                    client: client?.name,
                    isActive: <StatusCircle type={isActive ? 'apple' : 'tomato'} />,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit project">
                                    <NavLink to={`/projects/${id}/edit/general-info`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <ProjectDelete projectData={row} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/projects/create" label="Create new" />
            </PageHeader>

            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchProjects(params)}
            />
        </LayoutManager>
    );
};

export default Projects;
