import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Form, {
    Hidden,
    FileUploader,
    Creatable,
    Autocomplete,
    DateTime,
    Select,
    Currency,
    TextEditor
} from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import { employmentType } from 'erpcore/screens/Users/data/users';
import { salaryType, salaryPayoutCycle } from 'erpcore/data/money';

const EmploymentRecordForm = ({
    handleSubmit,
    form,
    title,
    subtitle,
    onCancel,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    const dispatch = useDispatch();

    const formValues = useSelector(state => getFormValues(form)(state)) || {};

    const { start_date: dateFrom, end_date: dateTo } = formValues;

    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    useEffect(() => {
        if (dateFrom || dateFrom === null) {
            setMinDate(moment(dateFrom).toDate());
        }
        if (dateTo || dateTo === null) {
            setMaxDate(moment(dateTo).toDate());
        }
    }, [dateFrom, dateTo]);

    const handleChangeDateFrom = value => {
        return setMinDate(moment(value).toDate());
    };

    const handleChangeDateTo = value => {
        return setMaxDate(moment(value).toDate());
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {!!title && <Form.SectionTitle>{title}</Form.SectionTitle>}
            {!!subtitle && subtitle}
            <Field component={Hidden} name="user" />
            <Form.Columns>
                <Form.Column>
                    <Field
                        name="start_date"
                        id="start_date"
                        fieldAttr={{ required: true }}
                        fieldProps={{
                            maxDate,
                            label: 'Start date',
                            showTimeSelect: false,
                            clearable: true,
                            icon: 'date',
                            iconPosition: 'right'
                        }}
                        component={DateTime}
                        validate={valueValidation([{ validator: 'required' }])}
                        onChange={handleChangeDateFrom}
                    />
                </Form.Column>
                <Form.Column>
                    <Field
                        name="end_date"
                        id="end_date"
                        fieldProps={{
                            minDate,
                            label: 'End date',
                            showTimeSelect: false,
                            clearable: true,
                            icon: 'date',
                            iconPosition: 'right'
                        }}
                        component={DateTime}
                        onChange={handleChangeDateTo}
                    />
                </Form.Column>
            </Form.Columns>
            <Form.Row>
                <Field
                    name="job_title"
                    id="job_title"
                    fieldProps={{
                        label: 'Job title',
                        options: {
                            endpoint: '/api/job-titles',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Columns>
                <Form.Column>
                    <Field
                        name="salary.amount"
                        id="salary.amount"
                        fieldProps={{
                            label: 'Salary amount',
                            clearable: true
                        }}
                        fieldAttr={{ required: true }}
                        component={Currency}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Column>
                <Form.Column>
                    <Field
                        name="salary.currency"
                        id="salary.currency"
                        fieldProps={{
                            label: 'Salary currency',
                            clearable: false,
                            options: {
                                endpoint: '/api/currencies',
                                mapData: {
                                    value: 'code',
                                    label: `{code} - {name}`
                                }
                            }
                        }}
                        fieldAttr={{ required: true }}
                        component={Autocomplete}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Column>
            </Form.Columns>
            <Form.Columns>
                <Form.Column>
                    <Field
                        name="salary_type"
                        id="salary_type"
                        fieldAttr={{ required: true }}
                        fieldProps={{
                            label: 'Salary type',
                            clearable: false,
                            options: salaryType
                        }}
                        component={Select}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Column>
                <Form.Column>
                    <Field
                        name="salary_payout_cycle"
                        id="salary_payout_cycle"
                        fieldAttr={{ required: true }}
                        fieldProps={{
                            label: 'Salary payout cycle',
                            clearable: false,
                            options: salaryPayoutCycle
                        }}
                        component={Select}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Column>
            </Form.Columns>
            <Form.Row>
                <Field
                    name="employment_type"
                    id="employment_type"
                    fieldAttr={{ required: true }}
                    fieldProps={{
                        label: 'Employment type',
                        clearable: false,
                        options: employmentType
                    }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="office"
                    id="office"
                    fieldProps={{
                        label: 'Office',
                        options: {
                            endpoint: '/api/offices',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="division"
                    id="division"
                    fieldProps={{
                        label: 'Division',
                        options: {
                            endpoint: '/api/divisions',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="department"
                    id="department"
                    fieldProps={{
                        label: 'Department',
                        options: {
                            endpoint: '/api/departments',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    component={Creatable}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="reports_to"
                    id="reports_to"
                    fieldProps={{
                        label: 'Reports to',
                        options: {
                            endpoint: '/api/users',
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name}'
                            }
                        }
                    }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Upload contract</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="contract"
                    id="contract"
                    fieldProps={{
                        allowedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'pdf']
                    }}
                    onFileUploaded={uploadedFile => {
                        const uploadedFileID = uploadedFile[0] ? uploadedFile[0].id : '';
                        dispatch(change(form, 'contract', uploadedFileID));
                    }}
                    component={FileUploader}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Comment</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="comment"
                    id="comment"
                    editorProps={{
                        toolbar: {
                            options: ['inline'],
                            inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline']
                            }
                        }
                    }}
                    component={TextEditor}
                />
            </Form.Row>

            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

EmploymentRecordForm.defaultProps = {
    onSubmit: () => {},
    form: 'EmploymentRecordForm',
    title: null,
    subtitle: null,
    onCancel: null,
    submitting: false,
    submitLabel: 'Create',
    pristine: false,
    invalid: false
};

EmploymentRecordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    enableReinitialize: true
})(EmploymentRecordForm);
