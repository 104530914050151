import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, Repeater, Media } from 'erpcore/components/Form';
import TitleImageRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleImageRepeaterItem';
import TitleUrlRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleUrlRepeaterItem';

const HomeTourForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, submitLabel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="home_tour_show_home_video_tour"
                    fieldProps={{
                        label: 'Show Home Video Tour',
                        clearable: true,
                        helperText: 'YouTube URL'
                    }}
                    fieldAttr={{ id: 'home_tour_show_home_video_tour' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="home_tour_sales_centre_video_tour"
                    fieldProps={{
                        label: 'Sales Centre Video Tour',
                        clearable: true,
                        helperText: 'YouTube URL'
                    }}
                    fieldAttr={{ id: 'home_tour_sales_centre_video_tour' }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Sales Centre 3D Tour</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="home_tour_sales_centre3d_tour"
                    component={Repeater}
                    RepeatableItem={TitleUrlRepeaterItem}
                    data={{
                        urlLabel: 'Matterport URL',
                        urlHelperText: 'Example: https://my.matterport.com/show/?m=xxxxxxxxxxx'
                    }}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.SectionTitleSmall>Gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="home_tour_gallery"
                    id="home_tour_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
            <Form.SectionTitleSmall>360 views</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="home_tour_panoramas"
                    component={Repeater}
                    RepeatableItem={TitleImageRepeaterItem}
                    data={{
                        mediaTitle: 'Pano Image'
                    }}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

HomeTourForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

HomeTourForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'ProjectForm',
    enableReinitialize: true
})(HomeTourForm);
