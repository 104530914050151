import React from 'react';
import PropTypes from 'prop-types';

import Tabs from 'erpcore/components/Tabs';

const ProjectEditTabs = ({ id }) => {
    const links = [
        {
            link: `/projects/${id}/edit/general-info`,
            label: 'General info'
        },
        {
            link: `/projects/${id}/edit/building-design`,
            label: 'Building design'
        },
        {
            link: `/projects/${id}/edit/neighbourhood`,
            label: 'Neighbourhood'
        },
        {
            link: `/projects/${id}/edit/floorplans`,
            label: 'Floor plans'
        },
        {
            link: `/projects/${id}/edit/home-tour`,
            label: 'Home tour'
        },
        {
            link: `/projects/${id}/edit/feature-and-options`,
            label: 'Feature & options'
        }
    ];

    return <Tabs links={links} />;
};

ProjectEditTabs.defaultProps = {
    id: 0
};

ProjectEditTabs.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProjectEditTabs;
