import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Image from 'erpcore/components/Image';
import { getOrganizationSettings } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import styles from './Hero.module.scss';

const Hero = ({ className }) => {
    const organizationSettings = useSelector(getOrganizationSettings);
    return (
        <Image
            iri={organizationSettings?.settings?.loginBackground}
            className={`${styles.hero} ${className || ''}`}
            isContainer
            fallback={<span className={`${styles.hero} ${className || ''}`}></span>}
        >
            <div className={styles.hero__content}>
                <Image
                    iri={organizationSettings?.settings?.logoInverted}
                    className={styles.hero__logo}
                />
            </div>
        </Image>
    );
};

Hero.defaultProps = {
    className: ''
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
