import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'erpcore/components/Modal';
import PermissionForm from 'erpcore/screens/Settings/Permissions/components/PermissionForm';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as permissionsActions } from 'erpcore/screens/Settings/Permissions/Permissions.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const PermissionEdit = ({ permissionData }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const initialValues = { name: permissionData.name };
    const params = useSelector(state => getQueryParams(state, { name: 'permissions' }));

    const onSubmitPermissionEditForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: permissionsActions.START_UPDATE_PERMISSION,
                permissionIri: permissionData?.iri,
                formData
            })
        )
            .then(() => {
                setModalOpened(false);

                return new Promise((resolve, reject) => {
                    dispatch({
                        promise: { resolve, reject },
                        type: listingActions.START_FETCHING_LISTING,
                        params,
                        entity: 'PERMISSIONS',
                        name: 'permissions',
                        endpoint: 'api/permissions'
                    });
                }).catch(error => {
                    return error;
                });
            })
            .catch(error => {
                setModalOpened(false);
                return error;
            });
    };

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    return (
        <Fragment>
            <Tooltip content="Edit permission">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={() => handleModal()}
                title={`Set label for ${permissionData?.attribute}.`}
            >
                <PermissionForm
                    onSubmit={onSubmitPermissionEditForm}
                    onCancel={() => handleModal()}
                    form={`PermissionEditForm-${permissionData?.iri}`}
                    initialValues={initialValues}
                />
            </Modal>
        </Fragment>
    );
};

PermissionEdit.defaultProps = {
    permissionData: {}
};

PermissionEdit.propTypes = {
    permissionData: PropTypes.oneOfType([PropTypes.object])
};

export default PermissionEdit;
