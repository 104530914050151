import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import qs from 'qs';

import CurrencyFormatter from 'erpcore/components/CurrencyFormatter';
import EmployeeRecordsFilterForm from 'erpcore/screens/Reports/EmployeeRecords/components/EmployeeRecordsFilterForm';
import EmployeeRecordsListing from 'erpcore/screens/Reports/EmployeeRecords/components/EmployeeRecordsListing';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Widget from 'erpcore/components/Widget';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingResponse,
    getListingFetching,
    getQueryParams
} from 'erpcore/components/Listing/Listing.selectors';
import { getOrganizationCurrencyData } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';

const EmployeeRecords = () => {
    const reducerName = 'employeeRecordsReport';
    const title = 'Report - Employee records';
    const formName = 'EmployeeRecordsFilterForm';

    const dispatch = useDispatch();
    const history = useHistory();
    const [apiParams, setApiParams] = useState(null);
    const { location } = { ...history };
    const { search, pathname } = { ...location };
    const parsedParams = qs.parse(search?.substr(1));
    const initialValues = {
        isActive:
            parsedParams?.isActive && parsedParams?.isActive !== '' ? parsedParams?.isActive : null,
        department:
            parsedParams?.department && parsedParams?.department !== ''
                ? parsedParams?.department
                : null,
        division:
            parsedParams?.division && parsedParams?.division !== '' ? parsedParams?.division : null,
        office: parsedParams?.office && parsedParams?.office !== '' ? parsedParams?.office : null,
        employmentType:
            parsedParams?.employmentType && parsedParams?.employmentType !== ''
                ? parsedParams?.employmentType
                : null
    };
    const filterValues = useSelector(state => getFormValues(formName)(state));
    const { isActive, department, division, office, employmentType } = { ...filterValues };
    const listingParams = useSelector(state => getQueryParams(state, { name: reducerName }));
    const listingData = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const organizationCurrencyData = useSelector(state => getOrganizationCurrencyData(state));

    // Fetch organization data
    const fetchOrganization = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_MINE_ORGANIZATION
            });
        }).catch(error => ({ error }));
    };

    // Fetch report data
    const fetchEmployeeRecords = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: 'REPORT_EMPLOYEE_RECORDS',
                name: reducerName,
                endpoint: 'api/reports/employee-records?include=jobTitle,department,reportsTo'
            });
        }).catch(error => {
            return error;
        });
    };

    // Used for API call params
    const transformFiltersToParams = () => {
        let isIsActive = null;
        if (isActive === 'active') {
            isIsActive = 'true';
        }
        if (isActive === 'inactive') {
            isIsActive = 'false';
        }

        const transformedParams = {
            'filters[is_active][equals]': isIsActive || null,
            'filters[department][equals]': department && department !== '' ? department : null,
            'filters[division][equals]': division && division !== '' ? division : null,
            'filters[office][equals]': office && office !== '' ? office : null,
            'filters[employment_type][equals]':
                employmentType && employmentType !== '' ? employmentType : null
        };

        return transformedParams;
    };

    //
    const onFilterChange = () => {
        // Handling params
        // For the API call
        const transformedFilterParams = transformFiltersToParams();
        const mergedParams = {
            ...listingParams,
            ...{ ...parsedParams[reducerName] },
            ...transformedFilterParams,
            ...{ page: 1 } // Reset page to 1
        };
        // For URL search/query params
        const newURLParams = { ...parsedParams, ...filterValues };
        if (newURLParams[reducerName]) {
            newURLParams[reducerName].page = 1; // Reset page to 1
        }
        // Cleanup
        Object.keys(newURLParams).map(key => {
            if (!newURLParams[key] || newURLParams[key] === '') {
                return delete newURLParams[key];
            }
            return newURLParams;
        });
        // Push URL query params
        history.push({
            pathName: pathname,
            search: qs.stringify(newURLParams, { encodeValuesOnly: true })
        });
        // Fetch
        fetchEmployeeRecords(mergedParams);
        setApiParams(mergedParams);
    };

    // Watch for filter values change
    useEffect(() => {
        // On component mount, form values are undefined and then in the next render they get initialValues set
        // we set initialValues to have null values instead of undefined
        // here we check if values are undefined, which means it is component mount, and in that case do not trigger filter change
        // to avoid uneccessary API calls
        if (
            isActive !== undefined ||
            department !== undefined ||
            division !== undefined ||
            office !== undefined ||
            employmentType !== undefined
        ) {
            onFilterChange();
        }
    }, [isActive, department, division, office, employmentType]);

    // Check if currency exist for the current/selected organization
    useEffect(() => {
        if (!organizationCurrencyData) {
            fetchOrganization();
        }
    }, [organizationCurrencyData]);

    const { meta } = { ...listingData };
    const { totalItems, totalItemsAll, monthlyCost, monthlyCostAll } = {
        ...meta
    };
    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <EmployeeRecordsFilterForm form={formName} initialValues={initialValues} />

                <PageContent.Row>
                    <Widget title="Overall company stats">
                        <Widget.Item iconName="globe" iconColor="apple" title="Active employees">
                            <span className="font-2">{totalItemsAll || '-'}</span>
                        </Widget.Item>
                        <Widget.Item iconName="dollar" iconColor="apple" title="Monthly cost">
                            <span className="font-2">
                                <CurrencyFormatter
                                    amount={
                                        monthlyCostAll?.amount !== undefined
                                            ? monthlyCostAll?.amount / 100
                                            : null
                                    }
                                    currency={monthlyCostAll?.currency || null}
                                />
                            </span>
                        </Widget.Item>
                    </Widget>
                    <Widget title="Currently filtered">
                        <Widget.Item iconName="group" iconColor="apple" title="Active employees">
                            <span className="font-2">{totalItems || '-'}</span>
                        </Widget.Item>
                        <Widget.Item iconName="dollar" iconColor="apple" title="Monthly cost">
                            <span className="font-2">
                                <CurrencyFormatter
                                    amount={
                                        monthlyCost?.amount !== undefined
                                            ? monthlyCost?.amount / 100
                                            : null
                                    }
                                    currency={monthlyCost?.currency || null}
                                />
                            </span>
                        </Widget.Item>
                    </Widget>
                </PageContent.Row>
                <Widget>
                    <EmployeeRecordsListing
                        reducerName={reducerName}
                        listingData={listingData}
                        listingFetching={listingFetching}
                        fetch={fetchEmployeeRecords}
                        apiParams={apiParams}
                        currency={organizationCurrencyData?.code}
                    />
                </Widget>
            </PageContent>
        </LayoutManager>
    );
};

export default EmployeeRecords;
