import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getFormValues, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import UserPermissionsForm from 'erpcore/screens/Users/components/UserPermissionsForm';

import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getUserData, getSingleUserFetching } from 'erpcore/screens/Users/Users.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getListingResponse } from 'erpcore/components/Listing/Listing.selectors';

const UserPermissions = ({ match }) => {
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const [initialValuesRoles, setInitialValuesRoles] = useState([]);
    const [initialValuesPermissions, setInitialValuesPermissions] = useState([]);
    const [permissionsEndpoint, setPermissionsEndpoint] = useState(
        '/api/permissions?filters[active][equals]=true'
    );
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const rolesData = useSelector(state => getListingResponse(state, 'roles'));
    const fetching = useSelector(getSingleUserFetching);
    const formValues = useSelector(state => getFormValues('UserPermissionsForm')(state));
    const initialValues = {
        roles: initialValuesRoles,
        permissions: initialValuesPermissions
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    // onSubmit
    const onSubmitUserPermissionsForm = formData => {
        if (!formData) {
            return false;
        }
        const { roles: dataRoles, permissions: dataPermissions } = { ...formData };
        const data = { roles: [...(dataRoles || []), ...(dataPermissions || [])] };
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_UPDATE_SINGLE_USER,
                iri: userIri,
                formData: data
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    // Fetch User Data
    const fetchUserData = () => {
        // Getting included data from API by setting params
        const params = {
            include: 'roles,permissions'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params
            });
        }).catch(error => ({ error }));
    };

    // Fetch Roles
    const fetchRoles = () => {
        const params = { pagination: false };

        dispatch({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'ROLES',
            name: 'roles',
            endpoint: 'api/roles'
        });
    };

    // Roles for the checkbox group
    const createRolesData = () => {
        const rolesArray = [];

        if (rolesData?.data) {
            rolesData.data.map(role => {
                rolesArray.push({ value: role.iri, id: role.iri, label: role.name });

                return rolesArray;
            });
        }

        return setRoles(rolesArray);
    };

    // Roles for the initialValues
    const createUserRolesData = () => {
        const rolesArray = [];

        if (userData?.roles) {
            userData.roles.map(role => {
                rolesArray.push(role?.iri);

                return rolesArray;
            });
        }

        return setInitialValuesRoles(rolesArray);
    };

    // Permissions for the initialValues
    const createUserPermissionsData = () => {
        const permissionsArray = [];

        if (userData?.permissions) {
            userData.permissions.map(permission => {
                permissionsArray.push(permission?.iri);

                return permissionsArray;
            });
        }

        return setInitialValuesPermissions(permissionsArray);
    };

    // Permissions dropdown endpoint and handling params
    const { roles: formValuesRoles } = { ...formValues };
    const getPermissionsEndpoint = () => {
        let permissionsEndpointWithParams = '/api/permissions?filters[active][equals]=true';
        if (formValuesRoles?.length > 0) {
            const permissionsParams = formValuesRoles
                .map(roleIRI => `exclude[]=${roleIRI}`)
                .join('&');
            permissionsEndpointWithParams = `/api/permissions?filters[active][equals]=true&${permissionsParams}`;
        }

        return setPermissionsEndpoint(permissionsEndpointWithParams);
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchUserData();
        fetchRoles();
    }, []);

    useEffect(() => {
        createUserPermissionsData();
        createUserRolesData();
    }, [userData]);

    useEffect(() => {
        createRolesData();
    }, [rolesData]);

    useEffect(() => {
        getPermissionsEndpoint();
    }, [formValuesRoles]);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <UserPermissionsForm
                    onSubmit={onSubmitUserPermissionsForm}
                    form="UserPermissionsForm"
                    initialValues={initialValues}
                    roles={roles}
                    permissionsEndpoint={permissionsEndpoint}
                />
            </PageContent>
        </LayoutManager>
    );
};

UserPermissions.defaultProps = {};

UserPermissions.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default withRouter(UserPermissions);
