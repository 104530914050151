import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Tabs from 'erpcore/components/Tabs';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const OrganizationTabs = () => {
    const meData = useSelector(getMeData) || {};
    const links = [];

    if (hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData)) {
        links.push({
            link: `/settings/organization/general`,
            label: 'General'
        });
        links.push({
            link: `/settings/organization/branding`,
            label: 'Branding'
        });
    }

    return <Tabs links={links} />;
};

export default withRouter(OrganizationTabs);
