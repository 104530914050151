import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import FloorplansForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/FloorplansForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const Floorplans = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const { floorplans = [] } = projectData;

    const initialValues = {
        floorplans: floorplans?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        })
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmitProjectEditForm = formData => {
        // send only changed data (except floorplans)
        const formDataDiff = diff(initialValues, formData);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: {
                    ...formDataDiff,
                    // send all floorplans data, not only changed
                    floorplans: cleanRepeaterOutput(formData.floorplans, ['customIdentifier'])
                }
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <FloorplansForm
                    onSubmit={onSubmitProjectEditForm}
                    form={`FloorplansForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

Floorplans.defaultProps = {
    match: {}
};

Floorplans.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(Floorplans);
