import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { Autocomplete, Select } from 'erpcore/components/Form';

import { employmentType } from 'erpcore/screens/Users/data/users';

const isActiveOptions = [
    {
        label: 'Active employees',
        value: 'active'
    },
    {
        label: 'Previous employees',
        value: 'inactive'
    },

    {
        label: 'Active & previous employees',
        value: null
    }
];

const EmployeeRecordsFilterForm = ({ handleSubmit, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="isActive"
                    fieldProps={{
                        label: 'Active employees',
                        clearable: true,
                        options: isActiveOptions
                    }}
                    fieldAttr={{ id: 'isActive' }}
                    component={Select}
                />
                <Field
                    name="department"
                    fieldProps={{
                        label: 'Department',
                        clearable: true,
                        options: {
                            endpoint: '/api/departments',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'department' }}
                    component={Autocomplete}
                />
                <Field
                    name="division"
                    fieldProps={{
                        label: 'Division',
                        clearable: true,
                        options: {
                            endpoint: '/api/divisions',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'division' }}
                    component={Autocomplete}
                />
                <Field
                    name="office"
                    fieldProps={{
                        label: 'Office',
                        clearable: true,
                        options: {
                            endpoint: '/api/offices',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'office' }}
                    component={Autocomplete}
                />
                <Field
                    name="employmentType"
                    fieldProps={{
                        label: 'Employment type',
                        clearable: true,
                        options: employmentType
                    }}
                    fieldAttr={{ id: 'employmentType' }}
                    component={Select}
                />
            </Form.Row>
        </Form>
    );
};

EmployeeRecordsFilterForm.defaultProps = {
    onSubmit: () => {}
};

EmployeeRecordsFilterForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func
};

export default reduxForm({
    form: 'EmployeeRecordsFilterForm',
    enableReinitialize: true
})(EmployeeRecordsFilterForm);
