import React from 'react';
import PropTypes from 'prop-types';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ProjectEditTabs from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditTabs';

const ProjectEditPageHeader = ({ data }) => {
    const { id, name } = { ...data };

    const pageTitle = () => {
        if (name) {
            return `Project - ${name}`;
        }

        return `Project`;
    };

    return (
        <>
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                <Button href="/projects/create" label="Create new" />
            </PageHeader>
            <ProjectEditTabs id={id} />
        </>
    );
};

ProjectEditPageHeader.defaultProps = {
    data: {}
};

ProjectEditPageHeader.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditPageHeader;
