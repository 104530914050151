import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ProjectForm from 'erpcore/screens/Projects/components/ProjectForm';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getIdFromIri } from 'erpcore/utils/dto';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const ProjectCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Create new project';

    const onSubmit = formData => {
        // Handle Location data
        const { location } = { ...formData };
        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_CREATE_PROJECT,
                formData
            });
        })
            .then(response => {
                if (response?.data?.id) {
                    history.push(`/projects/${getIdFromIri(response?.data?.id)}/edit/general-info`);
                } else {
                    dispatch(reset('ProjectCreateForm'));
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <ProjectForm onSubmit={onSubmit} form="ProjectCreateForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default ProjectCreate;
