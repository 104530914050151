import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ElementLoader from 'erpcore/components/ElementLoader';
import Form, { Switch } from 'erpcore/components/Form';
import './PermissionActiveToggle.scss';
import { actions as permissionsActions } from 'erpcore/screens/Settings/Permissions/Permissions.reducer';

const PermissionActiveToggle = ({ permissionIri, initialValue }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const onChange = (active, iri) => {
        setLoading(true);

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: permissionsActions.START_UPDATE_PERMISSION,
                permissionIri: iri,
                formData: { active }
            });
        }).then(() => {
            setLoading(false);
        });
    };

    return (
        <Form className="form--permissions-toggle">
            {loading && <ElementLoader overlay />}
            <Switch
                name="active"
                id="active"
                input={{
                    value: initialValue, // initial value
                    onChange: value => {
                        onChange(value, permissionIri);
                    }
                }}
                fieldProps={{
                    on: {
                        value: true,
                        id: 'true'
                    },
                    off: {
                        value: false,
                        id: 'false'
                    }
                }}
            />
        </Form>
    );
};

PermissionActiveToggle.defaultProps = {
    permissionIri: null,
    initialValue: false
};

PermissionActiveToggle.propTypes = {
    permissionIri: PropTypes.string,
    initialValue: PropTypes.bool
};

export default PermissionActiveToggle;
